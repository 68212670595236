import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import './style/style.scss';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // <React.StrictMode>
    <>
        <header className="header">
            <h1 className='logo'><span className='logo-icon'>K</span>evin</h1>
            <span className='subtitle'>by Bitgate</span>
        </header>
        <App />
    </>

    // {/* <ThemeDesigner /> */}
    // </React.StrictMode>
);
