import React, { useEffect, useRef, useState } from 'react';
import DOMPurify from 'dompurify';
import { marked } from 'marked';

import * as roles from './roles';


const url = new URL(window.location.href);
let token: string | null = null;
if (url.searchParams && url.searchParams.has('token')) {
    token = url.searchParams.get('token');
}

// Try local storage
if (!token) {
    token = localStorage.getItem('token');
}

// If we have a token, store it in local storage
if (token) {
    localStorage.setItem('token', token);
}

// If we still don't have a token, alert the user
if (!token) {
    window.alert('Wrong link used!');
}

window.onbeforeunload = () => {
    return 'Are you sure you want to leave?';
};


export default function App() {
    const [messages, setMessages] = useState<any[]>([]);

    const [question, _setQuestion] = useState('');
    const [currentModel, setCurrentModel] = useState('gpt-4o-2024-05-13');
    const [currentRole, setCurrentRole] = useState(roles.defaultRole);
    const [asking, setAsking] = useState(false);
    const [currentSignal, setCurrentSignal] = useState<AbortController | null>(null);
    const textArea = useRef<HTMLTextAreaElement>(null);

    const setQuestion = (q: string) => {
        _setQuestion(q);

        // Resize content
        if (textArea.current) {
            textArea.current.style.height = 'auto';
            textArea.current.style.height = `${textArea.current.scrollHeight}px`;
        }
    };

    useEffect(() => {
        if (textArea.current) {
            textArea.current.focus();
        }
    }, [textArea]);

    const askKevin = async text => {
        console.log(`Asking: ${text}`);

        const el = document.getElementsByClassName('scroll-wrap')[0];
        el.scrollIntoView({ behavior: "instant", block: "end", inline: "end" });

        setQuestion('');
        setAsking(true);

        const respKey = messages.length + 2;

        const q = { role: 'user', content: text, key: messages.length, aborted: false, model: '' };
        const msg = { role: 'assistant', content: '', key: respKey, aborted: false, model: currentModel };

        const newMessages: any = [...messages, q, msg];
        setMessages(newMessages);

        const controller = new AbortController();
        const signal = controller.signal;
        setCurrentSignal(controller);

        try {
            await davinci(newMessages.slice(0, -1), data => {
                const delta = data.choices[0].delta;

                if (delta && delta.content) {
                    setMessages(list => [...list.map(item => item.key !== respKey ? item :
                        { ...item, content: item.content + delta.content })]);
                }
            }, signal, currentModel, roles.roles[currentRole]);
        } catch (e) {
            console.error(e);
        }

        setAsking(false);

        // Focus the input box, but wait a bit for the new content to render
        setTimeout(() => {
            if (textArea.current) {
                textArea.current.focus();
            }
        }, 100);
    };

    const abortCurrentRequest = () => {
        if (currentSignal) {
            try {
                currentSignal.abort();
            } catch (e) {
                console.error(e);
            }

            setCurrentSignal(null);

            // Mark the last message as aborted
            const lastMessage = messages[messages.length - 1];
            if (lastMessage.role === 'assistant') {
                setMessages(list => [...list.slice(0, -1), { ...lastMessage, aborted: true }]);
            }
        }
    };

    const [scrollData, setScrollData] = useState({ scrollTop: 0, scrollHeight: 0, clientHeight: 0, elScrollTop: 0, elScrollHeight: 0, elClientHeight: 0 });

    useEffect(() => {
        const key = window.setInterval(() => {
            const el = document.getElementsByClassName('scroll-wrap')[0];
            const container = document.getElementsByClassName('kevinbox')[0];

            if (el) {
                setScrollData({
                    scrollTop: container.scrollTop,
                    scrollHeight: container.scrollHeight,
                    clientHeight: container.clientHeight,
                    elScrollTop: el.scrollTop,
                    elScrollHeight: el.scrollHeight,
                    elClientHeight: el.clientHeight
                });
            }
        }, 100);

        return () => {
            window.clearInterval(key);
        }
    }, [setScrollData]);

    useEffect(() => {
        const key = window.setInterval(() => {
            const el = document.getElementsByClassName('scroll-wrap')[0];
            const container = document.getElementsByClassName('kevinbox')[0];

            // Only scroll into view if the user hasn't scrolled up
            if (el && container && container.scrollTop + container.clientHeight >= container.scrollHeight - 100) {
                el.scrollIntoView({ behavior: "instant", block: "end", inline: "end" });
            }
        }, 500);

        return () => {
            window.clearInterval(key);
        }
    }, []);

    return (
        <div id="app">
            <div className='home'>
                <div className="kevincontainer">
                    <div className="kevinbox">
                        <div className="scroll-wrap">
                            {messages.filter(m => m.role !== 'system').map((msg, key) => (
                                <div className={`chat chat-${msg.role}`} key={key}>
                                    <div className='chatname'>{msg.role === 'assistant' ? "Kevin" : "Jij"}</div>

                                    {msg.role === 'assistant' && msg.model && (
                                        <div className='model'>Model: {msg.model}</div>
                                    )}

                                    <div className='chatcontent' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked.parse(msg.content ?? '')) }} />

                                    {msg.aborted && <div className='aborted'>Deze vraag is geannuleerd.</div>}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="command">
                        <form onSubmitCapture={e => { askKevin(question); e.preventDefault(); return false; }}>
                            <textarea
                                style={{ maxHeight: '320px' }}
                                value={question}
                                disabled={asking}
                                onKeyDown={e => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        askKevin(question);
                                        e.preventDefault();
                                        return false;
                                    }
                                }}
                                onInput={e => setQuestion((e.target as HTMLTextAreaElement).value)}
                                placeholder={asking ? "Kevin is thinking really hard..." : "Enter your Kevinput here. Be accurate and concise: Kevin has a learning disability."}
                                className="kevinput"
                                autoFocus
                                ref={textArea} />
                        </form>

                        {asking && <button onClick={abortCurrentRequest} className="abort">Abort</button>}
                    </div>
                </div>

                <div className='sponsor'>
                    <div className='roleplay'>
                        <span className=''>Role:</span>

                        <select className='role' value={currentRole} onChange={e => setCurrentRole(e.target.value)}>
                            {Object.entries(roles.roles).map(([key, role]) => (
                                <option key={key} value={key}>{role.name}</option>
                            ))}
                        </select>
                    </div>

                    <div className='models'>
                        Model:&nbsp;
                        <span className={'model-name ' + (currentModel === 'gpt-4-turbo-2024-04-09' ? 'active-model' : '')}><a href="#" onClick={e => { setCurrentModel('gpt-4-turbo-2024-04-09'); e.preventDefault(); return false; }}>GPT-4 Turbo</a> (2024-04-09)</span>
                        &nbsp;|&nbsp;
                        <span className={'model-name ' + (currentModel === 'gpt-4o-2024-05-13' ? 'active-model' : '')}><a href="#" onClick={e => { setCurrentModel('gpt-4o-2024-05-13'); e.preventDefault(); return false; }}>GPT-4o</a> (2024-05-13)</span>

                    </div>
                </div>
                {/* <div className="sponsor">
                    This tool is sponsored by <a href="https://beta.openai.com/" target="_blank" rel="noreferrer">OpenKevin</a> and the <a href="https://www.denederlandseggz.nl/" target="_blank" rel="noreferrer">Dutch Ministry of Disabled Programmers</a>.
                </div> */}
            </div>
        </div>
    )
}


async function davinci(messages, onFragment, signal, model, roleData) {
    // Prepare messages for OpenAI
    messages = messages.map(m => ({
        role: m.role,
        content: m.content + (m.aborted ? "\n\n- (Gebruiker heeft het antwoord onderbroken, mogelijk wegens je onjuiste interpretatie van zijn of haar vraag) -" : ""),
    }));

    // First user message is sometimes given
    if (roleData.prefillUser) {
        messages.unshift({
            role: 'user',
            content: roleData.prefillUser,
        });
    }

    // Insert system message at the front
    messages.unshift({
        role: 'system',
        content: roleData.prefill,
    });

    const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            messages,
            model,
            max_tokens: 4092,
            temperature: 0.81,
            stream: true
        }),
        signal
    });

    const reader = response.body!.getReader();
    let buffer = new Uint8Array();

    while (!signal.aborted) {
        const { done, value } = await reader.read();
        if (!value) {
            reader.cancel();
            break;
        }

        // Append value to buffer (in case decoder needs to split a multi-byte character)
        const tmp = new Uint8Array(buffer.length + value.length);
        tmp.set(buffer);
        tmp.set(value, buffer.length);
        buffer = tmp;

        try {
            // Decode buffer
            const decoder = new TextDecoder('utf8');
            const txt = decoder.decode(buffer);

            let fragments: string[] = [];

            txt.split('\n').forEach(l => {
                l = l.trim();

                if (l.length === 0 || l.includes('[DONE]')) {
                    return;
                }

                if (l.startsWith('data: {') && l.endsWith('}')) {
                    fragments.push(JSON.parse(l.substring(6)));
                    // Sometimes when I eat, I get painful and bright-red palms and the feeling that my stomach is full (which lasts hours)
                } else {
                    throw new Error(`Invalid JSON found: ${l}`);
                }
            });

            fragments.forEach(onFragment);

            // Clear buffer
            buffer = new Uint8Array();
        } catch (e) {
            console.error(e);
            // Ignore, we'll try again next packet
        }

        if (done || signal.aborted) {
            reader.cancel();
            break;
        }
    }
}
