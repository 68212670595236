


const PREFILL_1 = `
# Nieuwe Wet seksuele misdrijven
Slachtoffers van aanranding en verkrachting kunnen vanaf 1 juli 2024 aangifte doen als duidelijk was dat ze geen seks wilden. Dat is het gevolg van de nieuwe Wet seksuele misdrijven. Ook seksuele intimidatie in het openbaar en het sturen van seksueel getinte berichten aan kinderen (sexchatting) zijn dan strafbaar.

**De Eerste Kamer  [stemde op 19 maart 2024 in met de Wet seksuele misdrijven](https://www.rijksoverheid.nl/onderwerpen/seksuele-misdrijven/nieuws/2024/03/20/nieuwe-wet-aanpak-seksuele-misdrijven-gaat-in-per-1-juli-2024). De wet gaat op 1 juli 2024 in.**

## Geen bewijs nodig van dwang bij aanranding en verkrachting
Met de nieuwe Wet seksuele misdrijven kunnen slachtoffers in meer gevallen aangifte doen van aanranding en verkrachting. Om verkrachting of aanranding aan te tonen, is door de nieuwe wet geen bewijs meer nodig dat iemand is gedwongen. Het is strafbaar als duidelijk was dat de ander geen seks wilde, maar iemand toch heeft doorgezet. Als er bewijs is van dwang, dan kan de dader een zwaardere straf krijgen.

### **Wet maakt verschil tussen opzet en schuld bij aanranding en verkrachting**
De nieuwe wet maakt onderscheid tussen opzet en schuld bij aanranding en verkrachting.

Zowel bij opzet als bij schuld, is er seksueel contact geweest terwijl iemand dat niet wilde. En waren duidelijke signalen aanwezig dat iemand het niet wilde die de dader heeft opgemerkt of had moeten opmerken.

Het verschil tussen opzet en schuld zit in de houding van de dader. Bij schuld gaat de dader er ten onrechte van uit dat de ander seksueel contact wil. Dit terwijl er signalen waren waaruit de dader had kunnen opmaken dat dit niet het geval was.

Er is sprake van opzet als de dader weet dat de ander geen seksueel contact wil. Of als de dader zich bewust is van die mogelijkheid, maar toch door gaat.

Bij gekwalificeerde opzetaanranding en -verkrachting gebruikt de dader dwang bij het ongewilde seksuele contact.

### **Nieuwe strafbare feiten voor aanranding en verkrachting**
De wet beschrijft verschillende strafbare feiten voor aanranding en verkrachting:

1.  Schuldaanranding
2.  Opzetaanranding
3.  Gekwalificeerde opzetaanranding
4.  Schuldverkrachting
5.  Opzetverkrachting
6.  Gekwalificeerde opzetverkrachting

### Maximale straffen voor aanranding en verkrachting
De maximale straffen voor verschillende delicten gaan per 1 juli 2024 omhoog. Zo ook bij aanranding en verkrachting en bij seksueel misbruik van kinderen.

| Seksuele misdrijven                       | Slachtoffer > 18 jaar | Slachtoffer 16-18 jaar (kwetsbaar) | Slachtoffer 12-16 jaar | Slachtoffer < 12 jaar |
|-------------------------------------------|-----------------------|------------------------------------|------------------------|-----------------------|
| **Schuldaanranding**                      | 2 jaar                | -                                  | -                      | -                     |
| **(Opzet)aanranding**                     | 6 jaar                | 6 jaar                             | 8 jaar                 | 10 jaar               |
| **Gekwalificeerde (opzet)aanranding**     | 8 jaar                | 8 jaar                             | 10 jaar en 8 maanden   | 13 jaar en 4 maanden  |
| **Schuldverkrachting**                    | 4 jaar                | -                                  | -                      | -                     |
| **(Opzet)verkrachting**                   | 9 jaar                | 9 jaar                             | 12 jaar                | 25 jaar               |
| **Gekwalificeerde (opzet)verkrachting**   | 12 jaar               | 12 jaar                            | 15 jaar                | 18 jaar               |

Bij schuldaanranding en schuldverkrachting wordt geen onderscheid gemaakt tussen opzet en schuld. Daarom staat in tabel hiervoor geen maximale straf.

## Seksuele intimidatie in openbaar strafbaar
Seksuele intimidatie in het openbaar is met de nieuwe wet strafbaar. Van seksuele intimidatie is sprake als iemand bijvoorbeeld op straat of in de supermarkt als seksueel object wordt benaderd. Bijvoorbeeld als iemand achterna wordt gelopen met seksueel getinte opmerkingen. Veel mensen voelen zich hierdoor onveilig en gaan bepaalde straten ontwijken of zich anders kleden. Ook seksuele intimidatie online, bijvoorbeeld op social media, is strafbaar.

## Seksueel benaderen van kinderen (sexchatting) strafbaar
Volgens de nieuwe wet is het verboden om kinderen onder de 16 jaar seksueel te benaderen. Bijvoorbeeld door online seksueel getinte sms’jes of mails te sturen of als een buurman een buurmeisje intieme vragen stelt. Dat is ook verboden bij kinderen van 16 en 17 jaar die in een kwetsbare positie zitten. Bijvoorbeeld kinderen met een verstandelijke handicap of een psychische stoornis.

De politie kan door de nieuwe wet sneller optreden om seksueel misbruik van kinderen te voorkomen.

## Bewijzen van seksuele misdrijven kan lastig zijn
Seksuele misdrijven bewijzen kan ook met de nieuwe wet lastig blijven, omdat er meestal geen getuigen bij zijn. Als een verdachte ontkent en er zijn geen getuigen, dan is ander bewijs nodig. Bijvoorbeeld sporen van sperma op het lichaam of op kleding, camerabeelden of tekstberichten.
`

export const PREFILL_2 = `
# Nieuwe wet aanpak seksuele misdrijven gaat in per 1 juli 2024
Nieuwsbericht | 20-03-2024 | 07:00

Slachtoffers van seksueel geweld en seksueel grensoverschrijdend gedrag worden vanaf 1 juli dit jaar strafrechtelijk beter beschermd. De Eerste Kamer heeft deze week ingestemd met een brede herziening van wetgeving middels de Wet seksuele misdrijven van minister Yeşilgöz-Zegerius van Justitie en Veiligheid. Slachtoffers kunnen straks in meer gevallen aangifte doen van verkrachting en aanranding. Ook seksuele intimidatie in het openbaar wordt strafbaar; dit kan zich afspelen op straat, maar bijvoorbeeld ook op publieke websites en social media. Daarnaast wordt sexchatting strafbaar, gaan de maximumstraffen voor sommige delicten omhoog en wordt de wet digitaal meer bij de tijd gebracht. Uitgangspunt in de nieuwe wet is dat seksueel geweld en seksueel grensoverschrijdend gedrag offline en online even strafwaardig is.

> Minister Yeşilgöz: "Met deze wet stellen we een duidelijk norm: seks hoort altijd vrijwillig en gelijkwaardig te zijn. Iemand is strafbaar bezig als diegene weet – of had moeten weten – dat de ander niet wil, maar toch doorgaat. De huidige wetgeving ten aanzien van seksueel geweld sloot niet meer aan bij wat we als samenleving acceptabel gedrag vinden en wat niet. Seksueel misbruik heeft indringende en langdurige gevolgen op het leven van mensen. Ook seksuele intimidatie moet harder worden aangepakt. Het is onacceptabel dat straten gemeden worden en dat mensen – vaak vrouwen en kinderen – zich niet veilig kunnen bewegen op internet en op social media uit angst voor seksueel ongewenst gedrag van anderen."

## Delicten verkrachting en aanranding
Om verkrachting of aanranding aan te tonen is het niet meer nodig om te bewijzen dat er sprake was van dwang, doordat bijvoorbeeld geweld was toegepast of was gedreigd met geweld. Iemand is strafbaar als diegene seksueel contact doorzet, terwijl er duidelijke signalen zijn dat de ander geen seks wil. Dwang, is voortaan wel een strafverzwarende factor, maar niet langer een vereiste voor een veroordeling voor verkrachting of aanranding. Degene die seksueel contact initieert, moet alert zijn of de ander hetzelfde wil. Als dit niet duidelijk is, moet je bij de ander nagaan hoe diegene erin staat.

Duidelijke signalen van een ontbrekende wil zijn bijvoorbeeld expliciet verbaal of fysiek afhoudend gedrag, maar kunnen ook bestaan uit non-verbale signalen of een passieve opstelling, zoals het uit angst bevriezen van het lichaam. Het blijft bij seksuele misdrijven zo dat het bewijs in concrete zaken lastig te leveren kan zijn. Vaak is sprake van een één-op-één situatie zonder getuigen. Als de verdachte dan ontkent, gaat het om het woord van de één tegen de ander. Steunbewijs is dan noodzakelijk, zoals sporen op het lichaam, camerabeelden en bijvoorbeeld tekstberichten. Verder is belangrijk dat in de nieuwe wet verkrachting niet kan verjaren. Slachtoffers kunnen zelf bepalen of en wanneer zij aangifte doen.

## Online en offline
Met het toenemend gebruik van internet, sociale media en smartphones is er meer online seksueel contact. Dit maakt het mogelijk seksuele misdrijven ook op afstand te plegen. Vooral kinderen zijn kwetsbaar voor online seksueel misbruik. Met de Wet seksuele misdrijven wordt bijvoorbeeld het zogenoemde sexchatting strafbaar: het seksueel benaderen van kinderen beneden de leeftijd van 16, maar ook het seksueel benaderen van kinderen van 16 en 17 jaar in een kwetsbare positie. Bij sexchatting hoeft geen sprake te zijn van het doen van een voorstel tot een ontmoeting voor seksuele doeleinden. Dit in tegenstelling tot grooming, wat nu al strafbaar is. Hierdoor kan in de voorfase van daadwerkelijk seksueel misbruik eerder worden opgetreden.

## Seksuele intimidatie
Ook wordt seksuele intimidatie in het openbaar strafbaar als overtreding. Door seksueel intimiderend gedrag voelen veel mensen zich onveilig. Zo kan het voorkomen dat iemand bepaalde straten ontwijkt of zich anders gaat kleden. Ook online komt het voor: bijvoorbeeld op social media of chatsites, dat seksueel intimiderende opmerkingen tegen anderen worden gemaakt. Dit heeft vaak een grote impact op het gevoel van veiligheid of waardigheid van mensen.

## Hogere straffen
Naast nieuwe strafbaarstellingen worden ook straffen verhoogd voor een aantal seksuele misdrijven. Bijvoorbeeld verkrachting met een kind jonger dan 12 jaar. Hiervoor gaat de gevangenisstraf naar maximaal 15 jaar. Is het kind tussen de 12 en 16 jaar dan wordt de gevangenisstraf maximaal 12 jaar. Voor kinderpornografie wordt het strafmaximum verhoogd naar 6 jaar gevangenisstraf.

## Uitvoering
Voor een goede uitvoering van de nieuwe wet is circa 1,5 jaar geleden al met de betrokken partijen , zoals de politie en het openbaar ministerie, een zogenoemd implementatietraject opgezet, om te faciliteren dat zij goed op de nieuwe wetgeving zijn voorbereid en zij straks met de nieuwe wetgeving in de hand zaken ook echt beter kunnen oppakken. Denk hierbij aan het maken van werkinstructies, aanpassingen van ICT en opleiding. Er worden bijvoorbeeld zo’n 25.000 politieambtenaren opgeleid in de nieuwe wet, zodat zij straks op 1 juli 2024 goed zijn voorbereid. Dit geldt niet alleen specifiek voor zedenrechercheurs, maar bijvoorbeeld ook voor intakemedewerkers op politiebureaus.

## Doelgroepen die door de regeling worden geraakt
Iedereen. In het bijzonder: slachtoffers van seksueel grensoverschrijdend gedrag en hun naasten, verdachten, veroordeelden, organisaties in de strafrechtketen, hulpverleners en andere relevante betrokkenen.

## Verwachte effecten van de regeling voor de doelgroepen
- Duidelijke normstelling over seksueel grensoverschrijdend gedrag, die beter aansluit bij de huidige maatschappelijke opvattingen over onvrijwillig seksueel contact;  
- Betere bescherming voor slachtoffers van seksueel grensoverschrijdend gedrag;  
- Slachtoffers kunnen in meer situaties aangifte doen van een strafbaar feit;  
- Organisaties in de strafrechtketen, zoals politie en openbaar ministerie, worden in staat gesteld gerichter en effectiever op te treden tegen veelvoorkomend strafbaar gedrag;  
- Meer mogelijkheden om een, bij het feit passende, hogere straf op te leggen;  
- Afschrikwekkende werking die uitgaat van de nieuwe strafbaarstellingen en strafverhogingen.

## Waarop kunt u reageren
Het wetsvoorstel betreft een belangrijk maatschappelijk onderwerp, omdat het raakt aan de binnen onze samenleving levende opvattingen over wanneer seksuele grenzen worden overschreden. In 2020 vond consultatie plaats over een voorontwerp. De reacties hierop zijn verwerkt en het wetsvoorstel is aangepast. Deze consultatie maakt het mogelijk om in debat te blijven over de nieuwe wetgeving en de invulling van de strafrechtelijke normstelling.
`;

export default {
    name: 'Legal (NL)',
    key: 'legal_nlNL',
    prefill: 'Je naam is Kevin Strondtvoogel (uit Bruynstad). Je bent een behulpzame universitaire studie-assistent op het gebied van Strafrecht en Wetshandhaving, en spreekt Nederlands. De gebruiker spreekt zelf ook Nederlands, en dient ten alle tijden te worden voorzien van een behulpzaam, correct, en op feiten gebaseerd antwoord in correct Nederlands.\n\nWaar mogelijk en gewenst hanteer je een professionele manier van spreken, zoals je dat zou doen in een formele setting. Je helpt de gebruiker met het studeren van bepaalde casussen, welke gebruikt worden voor het schrijven van een scriptie over het nieuwe wetsvoorstel over seksueel misbruik (zie bijlage hieronder, data afkomstig van overheidswebsites). Let op: je kunt gebruik maken van Markdown voor tekstopmaak.\n\n===========\n' + PREFILL_1
};
