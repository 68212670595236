import legal_nlNL from "./legal_nlNL";
import scriptie_nlNL from "./scriptie_nlNL";
import medical_enUS from "./medical_enUS";
import coa_generic_enUS from "./coa_generic_enUS";

export const roles = {
    [legal_nlNL.key]: legal_nlNL,
    [scriptie_nlNL.key]: scriptie_nlNL,
    [medical_enUS.key]: medical_enUS,
    [coa_generic_enUS.key]: coa_generic_enUS,
};

export const defaultRole = legal_nlNL.key;
